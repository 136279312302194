<template>
  <div
    v-show="show"
    class="cart-checkout__discountDetails-wrapper j-checkout-discountDetails"
  >
    <div
      class="cart-checkout__discountDetails"
    >
      <div
        class="cart-checkout__discountDetails-title"
        tabindex="0"
      >
        {{ isSuggested ? language.SHEIN_KEY_PWA_30777 : language.SHEIN_KEY_PWA_17525 }}
      </div>
      
      <div class="cart-checkout__discountDetailsSection">
        <div class="checked-item-imgs">
          <MiniImgInfo
            v-for="id in checkedItemIds"
            :key="id"
            class="item-img"
            :item="cartItemMap[id] || {}"
          />
        </div>
        <!-- retail-price -->
        <div
          v-if="showRetailPrice"
          class="retail-price"
        >
          <div class="discount-li no-padding">
            <div class="left-label">
              {{ retailPriceTitle }}
            </div>
            <span class="right-price">{{ retailPrice }}</span>
          </div>
          <div
            v-if="showSuggestedSalePrice"
            class="retail-price__sub"
          >
            <span>{{ language.SHEIN_KEY_PWA_31353 }}&nbsp;</span>
            <span class="retail-price__line-through">{{ suggestedSalePrice }}</span>
          </div>
        </div>

        <!-- promotion -->
        <template v-if="isSuggested">
          <ChecklistPromotions
            type="promotionA"
            :is-suggested="isSuggested"
            :right-price="promotionRightPriceA"
            :list="promotionListA"
            :promotion-count-down="promotionCountDownA"
            @click-tip="handleDialogShow"
          />
          <!-- every body price -->
          <div
            v-if="isPromotionB || primeDiscount"
            class="retail-price discount-li"
          >
            <div class="left-label">
              {{ language.SHEIN_KEY_PWA_30771 + ':' }}
            </div>
            <span class="right-price">{{ cartInfo.every_body_price.amountWithSymbol }}</span>
          </div>
          <ChecklistPromotions
            ref="promotionBBox"
            type="promotionB"
            :is-suggested="isSuggested"
            :right-price="promotionRightPriceB"
            :list="promotionListB"
            :promotion-count-down="promotionCountDownB"
            @click-tip="handleDialogShow"
          />
        </template>
        <template v-else>
          <ChecklistPromotions
            ref="promotionBox"
            :is-suggested="isSuggested"
            :right-price="promotionRightPrice"
            :list="promotionList"
            :promotion-count-down="promotionCountDown"
            @click-tip="handleDialogShow"
          />
        </template>

        <!-- shein club price -->
        <template v-if="primeDiscount">
          <div class="prime-discount discount-li">
            <div class="left-label">
              <span>{{ primeDiscount.tip }}</span>
              <i
                v-if="primeVipOverQuotaTip"
                class="suiiconfont sui_icon_doubt_16px_2"
                @click.stop="() => handleDialogShow(primeVipOverQuotaTip)"
              >
              </i>
            </div>
            <div class="right-price">
              <Icon
                name="sui_icon_club_logo_fill_14px"
                size="15px"
                :color="primeColor"
              />
              <span :style="{ color: primeColor }">{{ '-' + primeDiscount.discount_price.amountWithSymbol }}</span>
            </div>
          </div>
        </template>
        <!-- subtotal-price -->
        <div
          v-if="showSubTotal"
          class="subtotal-price discount-li"
        >
          <div class="left-label">
            {{ language.SHEIN_KEY_PWA_29754+':' }}
          </div>
          <span class="right-price">{{ cartInfo.total_price_before_coupon.amountWithSymbol }}</span>
        </div>
        <template v-if="showEstimated">
          <!-- coupon-price -->
          <div
            v-if="couponInfos.length > 0"
            class="coupon-price discount-li"
          >
            <div class="left-label">
              <span>{{ language.SHEIN_KEY_PWA_29755+':' }}</span>
              <i 
                class="suiiconfont sui_icon_doubt_16px_2"
                @click.stop="() => handleDialogShow(isSuggested ? language.SHEIN_KEY_PWA_30775 : language.SHEIN_KEY_PWA_24705)"
              >
              </i>
              <count-down-time
                v-if="couponCountDown >= 0"
                :time-stamp="couponCountDown"
                :notReload="true"
                :notHide="true"
                class="discount-countdown"
                font-size="10px"
              />
            </div>
            <div class="right-price">
              -{{ cartInfo.coupon_discount_price.amountWithSymbol }}
              <span
                class="discount-icon"
                @click="handleFold('coupon')"
              >
                <Icon
                  v-if="isCouponFold"
                  key="coupon-down"
                  name="sui_icon_more_down_16px"
                />
                <Icon
                  v-else
                  key="coupon-up"
                  name="sui_icon_more_up_16px"
                />
              </span>
            </div>
          </div>
          <!-- coupons-box -->
          <div
            v-if="couponInfos.length > 0"
            v-show="!isCouponFold"
            ref="couponsBox"
            class="coupons-box"
          >
            <div
              v-for="coupon in couponInfos"
              :key="coupon.coupon_code"
              class="coupon-item"
            >
              <div class="coupon-item-imgs">
                <MiniImgInfo
                  v-for="id in (coupon.ids || [])"
                  :key="id+coupon.id"
                  :item="cartItemMap[id] || {}"
                  :options="{
                    width: 32,
                    height: 32,
                    showCheckbox: false,
                    showStock: false
                  }"
                  class="item-img"
                />
              </div>
              <div class="coupon-item-content">
                <div class="coupon-card">
                  <EasyCoupon
                    :lang-text="language.SHEIN_KEY_PWA_24704"
                    :showLangText="coupon.showLangText"
                    :coupon-code="coupon.coupon_code"
                  />
                </div>
                <div class="coupon-discount">
                  {{ template(coupon.discount_price && coupon.discount_price.amountWithSymbol, language.SHEIN_KEY_PWA_23921) }}
                </div>
              </div>
            </div>
          </div>
          <!-- points-price -->
          <div
            v-if="autoUsePoints.usePoints"
            class="points-price discount-li"
          >
            <div class="left-label">
              <span>{{ language.SHEIN_KEY_PWA_29756+':' }}</span>
              <i
                class="suiiconfont sui_icon_doubt_16px_2"
                @click.stop="() =>handleDialogShow(isSuggested ? language.SHEIN_KEY_PWA_30775 : language.SHEIN_KEY_PWA_27109)"
              ></i>
            </div>
            <div class="right-price">
              {{ '-'+autoUsePoints.amountWithSymbol }}
            </div>
          </div>
          <!-- estimated-price -->
          <div
            class="estimated-price discount-li"
          >
            <div class="left-label">
              {{ priceTitleText }}:
            </div>
            <span
              class="right-price"
              v-html="totalPriceAmountWithSymbol"
            ></span>
          </div>
          <CartBNPLPopover
            v-if="showCartBNPLPopover"
            :show-close="false"
            class="BNPLPopover"
            placement="top"
          />
          <!-- estimated-tip -->
          <div class="estimated-tip">
            {{ isSuggested ? language.SHEIN_KEY_PWA_30820 : language.SHEIN_KEY_PWA_29758 }}
          </div>
        </template>
        <template v-else>
          <!-- 不显示到手价时，放到 subtotal 下方展示 -->
          <CartBNPLPopover
            v-if="showCartBNPLPopover"
            :show-close="false"
            class="BNPLPopover"
            placement="top"
          />
        </template>
      </div>
      <s-dialog
        :visible.sync="dialogShow"
        append-to-body
      >
        <template slot="title">
          {{ dialogTip }}
        </template>
        <template
          slot="footer"
        >
          <s-button
            width="100%" 
            :type="['primary', 'H72PX']"
            @click="dialogShow=!dialogShow"
          >
            {{ language.SHEIN_KEY_PWA_15146 }}
          </s-button>
        </template>
      </s-dialog>
      <i
        v-enterkey
        class="cart-checkout__discountDetails-closeBtn iconfont icon-close"
        tabindex="0"
        role="button"
        @click="close"
      ></i>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter'
import EasyCoupon from '../coupon/EasyCoupon.vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { template } from '@shein/common-function'
import MiniImgInfo from './miniImgInfo.vue'
import ChecklistPromotions from './ChecklistPromotions.vue'
import { amountWithSymbolTransform, triggerHighlightAnimation } from '../../utils'
import CartBNPLPopover from '../CartBNPLPopover.vue'

daEventCenter.addSubscriber({ modulecode: '1-8-7' })

export default {
  name: 'CheckoutDiscountDetails',
  components: {
    EasyCoupon,
    MiniImgInfo,
    ChecklistPromotions,
    Icon,
    CartBNPLPopover,
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    checkedItemIds: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      dialogShow: false,
      dialogTip: '',
      isPromotionFold: true,
      isCouponFold: true,
    }
  },
  computed: {
    ...mapState('cart', ['cartInfo', 'language', 'integrityPromotionInfo', 'cartAbtInfo', 'cartItemMap', 'cartsId', 'locals', 'soldoutIds', 'integrityPromotionInfo']),
    ...mapGetters('cart', ['isSuggested', 'showPrimeVipOverQuota', 'primeVipOverQuotaTip', 'flashSaleGroupList', 'underPriceAbtValue', 'payShowStyleB', 'payShowStyleA', 'cartDiscountLogoA', 'isNewPrice']),
    isSuggestedSpecialDe() {
      return this.isSuggested === 'special_de'
    },
    isPromotionA() {
      return !!this.promotionListA.length
    },
    isPromotionB() {
      return !!this.promotionListB.length
    },
    showEstimated() {
      if (this.isSuggested) {
        // 合规站点 有 券 或者 积分 才展示到手价
        return this.couponInfos.length || this.autoUsePoints.usePoints
      }
      return true
    },
    showCartBNPLPopover() {
      return this.payShowStyleB || this.payShowStyleA
    },
    showRetailPrice() {
      if (this.isSuggested) {
        return this.isPromotionA
      }
      return this.promotionList.length > 0
    },
    retailPriceTitle() {
      if (this.isSuggested) {
        if (this.isSuggestedSpecialDe) return this.language.SHEIN_KEY_PWA_31352
        return this.language.SHEIN_KEY_PWA_30769
      }
      return this.language.SHEIN_KEY_PWA_29752
    },
    retailPrice() {
      if (this.isSuggested && !this.isSuggestedSpecialDe) {
        return this.cartInfo.crossed_price.amountWithSymbol
      }
      return this.cartInfo.originPrice.amountWithSymbol
    },
    showSuggestedSalePrice() {
      return this.isSuggestedSpecialDe &&
        !!+this.cartInfo.suggested_sale_price?.amount
    },
    suggestedSalePrice() {
      return this.cartInfo.suggested_sale_price?.amountWithSymbol
    },
    promotion_discount_info () {
      let discountInfo = this.cartInfo.promotion_discount_info || []
      let primeVip = discountInfo.find(item => item.typeId == 29)
      if(!primeVip) {
        if(this.showPrimeVipOverQuota) {
          discountInfo.push({
            id: 'noPrimeVip',
            typeId: 29,
            discount_price: {
              'amount': '0',
              'amountWithSymbol': '0.00',
              'usdAmount': '0',
              'usdAmountWithSymbol': '0.00'
            }
          })
        }
      }
      return discountInfo
    },
    other_discount_price () {
      return this.cartInfo.other_discount_price || {}
    },
    couponInfos() {
      const { promotion_discount_info, coupon_infos } = this.cartInfo
      const arr = coupon_infos.map((v) => ({
        ...v,
        showLangText: true,
        ids: this.cartsId.filter(id => {
          let cartItem = this.cartItemMap[id]
          return cartItem?.per_coupon_discount?.some(coupon => coupon.coupon_code == v.coupon_code) && cartItem?.is_checked > 0 && !this.soldoutIds.includes(id)
        }) || []
      }))

      const specialCoupon = promotion_discount_info.find(p => p.typeId == 32)

      // 将活动作为优惠券传入并按价格从高到低排序
      if (specialCoupon) {
        arr.push({
          ...specialCoupon,
          showLangText: false,
          coupon_code: this.language.SHEIN_KEY_PWA_26427,
          ids: this.filterCheckedIdsByPromoIds(specialCoupon.promotion_ids)
        })
        arr.sort((a, b) => (b?.single_discount_value?.amount || 0) - (a?.single_discount_value?.amount || 0))
      }
      return arr
    },
    promotionRightPrice() {
      return this.cartInfo.total_promotion_discount_without_prime || {}
    },
    promotionRightPriceA() {
      return this.cartInfo.every_body_promotion_discount || {} // everybodyprice 之上的优惠金额
    },
    promotionRightPriceB() {
      return this.cartInfo.exclusive_promotion_discount || {} // everybodyprice 之下的优惠金额
    },
    promotionList(){
      const promotionMap = {}
      const showDetailTipTids = [1, 9, 14, 15, 16, 17, 18, 19, 25, 26]
      const showTitleTipTids = [2, 3, 4, 8, 10, 11, 12, 13, 22, 24, 28, 30, 33]
      const titleLangMap = {
        '2': this.language.SHEIN_KEY_PWA_14961,
        '3': this.language.SHEIN_KEY_PWA_17527,
        '4-0': this.language.SHEIN_KEY_PWA_14961,
        '4-8': this.language.SHEIN_KEY_PWA_31167,
        '8': this.language.SHEIN_KEY_PWA_17528,
        '10-1': this.language.SHEIN_KEY_PWA_14949, // 常规闪购
        '10-2': this.language.SHEIN_KEY_PWA_21931, // 新人闪购
        '10-3': this.language.SHEIN_KEY_PWA_21933, // 特殊闪购
        '10-4': this.language.SHEIN_KEY_PWA_26340, // 分人群专区闪购
        '10-5': this.language.SHEIN_KEY_PWA_26340, // 不分人群专区闪购
        '10-6': this.language.SHEIN_KEY_PWA_29800, // 品牌特卖
        '11': this.language.SHEIN_KEY_PWA_25021,
        '12': this.language.SHEIN_KEY_PWA_17529,
        '13': this.language.SHEIN_KEY_PWA_15828,
        '22': this.language.SHEIN_KEY_PWA_15828,
        '24': this.language.SHEIN_KEY_PWA_17531,
        '28': this.language.SHEIN_KEY_PWA_14961,
        // '29': this.language.SHEIN_KEY_PWA_21802 + ':',
        '30': this.underPriceAbtValue ? this.language.SHEIN_KEY_PWA_23491 : this.language.SHEIN_KEY_PWA_23492
      }
      
      this.promotion_discount_info.forEach(item => {
        const id = item.id
        const tid = +item.typeId

        // Price Drop 情况
        if (tid == 31 ) {
          if(item.promotion_logo_type == 3) {
            promotionMap[id] = {
              every_body_price_type: item.every_body_price_type,
              tip: this.language.SHEIN_KEY_PWA_26878,
              discount_price: item.discount_price,
              ids: this.filterCheckedIdsByPromoIds(item.promotion_ids),
            }
          } else if(item.promotion_logo_type != 1 && item.promotion_logo_type != 3) {
            promotionMap[id] = {
              every_body_price_type: item.every_body_price_type,
              tip: this.language.SHEIN_KEY_PWA_27511,
              discount_price: item.discount_price,
              ids: this.filterCheckedIdsByPromoIds(item.promotion_ids),
            }
          } else { // promotion_logo_type == 1 的情况, 非app端理论不会出现该情况
            promotionMap[id] = {
              tip: this.language.SHEIN_KEY_PWA_27511,
              discount_price: item.discount_price,
              ids: this.filterCheckedIdsByPromoIds(item.promotion_ids),
            }
          }
        }

        if(item.discount_price_before_insured_price) {
          promotionMap['insured_discount'] = {
            every_body_price_type: item.every_body_price_type,
            tip: this.language.SHEIN_KEY_PWA_22045,
            discount_price: item.discount_price_before_insured_price,
            ids: this.cartsId.filter(id => {
              let cartItem = this.cartItemMap[id]
              return cartItem?.product?.insurable == 1 && cartItem?.is_checked > 0 && !this.soldoutIds.includes(id)
            })
          }
        }

        const isShowDetailTip = showDetailTipTids.includes(tid)
        const isShowTitleTip = showTitleTipTids.includes(tid)
        if (!isShowDetailTip && !isShowTitleTip) return

        if (!promotionMap[id]) promotionMap[id] = { typeId: tid, id: id }
        promotionMap[id].discount_price = item.discount_price
        promotionMap[id].every_body_price_type = item.every_body_price_type

        if (showDetailTipTids.includes(tid)) {
          let promotionInfoContent = this.integrityPromotionInfo[id]?.content || {}
          if (promotionInfoContent.tips) {
            const tipsIndex = promotionInfoContent.tipsIndex
            const tipsList = promotionInfoContent.tips.list
            promotionMap[id].tip = tipsList[tipsIndex] || ''
            promotionMap[id].subTip = promotionInfoContent.discountListSubTip || ''
          }
        } else if (showTitleTipTids.includes(tid)) {
          // 闪购活动
          if (tid === 10) {
            const { flash_type } = this.integrityPromotionInfo[id]
            if ([4, 5].includes(flash_type) && this.flashSaleGroupList[id]?.[0].promotionTag?.typeIdText) {
              // 要求与商品行展示的活动名称一致
              promotionMap[id].tip = this.flashSaleGroupList[id]?.[0].promotionTag?.typeIdText // 获取闪购活动第一个商品的 typeIdText
            } else {
              promotionMap[id].tip = titleLangMap[`${tid}-${flash_type}`]
            }
            // 超限购买提示
            if(this.flashSaleGroupList[id] && this.integrityPromotionInfo[id]?.limit_mode == 1){ // 闪购活动且支持超限原价购买
              const target = this.flashSaleGroupList[id].find(cartItem => cartItem.is_checked == 1 && (cartItem.product.product_promotion_info?.some(promo => promo.single_over == 1 || promo.member_over == 1)))
              if (target) promotionMap[id].subTip = this.language.SHEIN_KEY_PWA_23038
            }
          } else if(tid === 4) { // 全场满赠（全场满赠、付费会员权益礼物）
            const { promotion_logo_type } = this.integrityPromotionInfo[id]
            promotionMap[id].tip = titleLangMap[`${tid}-${promotion_logo_type}`] || titleLangMap[`${tid}-0`]
          } else if (tid == 33) {
            let catNameMulti = this.integrityPromotionInfo[id]?.category_info?.[0]?.cat_name_multi || ''
            promotionMap[id].tip = template(catNameMulti, this.language.SHEIN_KEY_PWA_31149)
            promotionMap[id].subTip = this.language.SHEIN_KEY_PWA_31148
          } else {
            promotionMap[id].tip = titleLangMap[tid]
          }
        }
        promotionMap[id].ids = this.filterCheckedIdsByPromoIds(item.promotion_ids)
      })
      let otherDiscountPrice = this.cartInfo.other_discount_price
      if(otherDiscountPrice?.amount > 0) {
        promotionMap['specials'] = {
          every_body_price_type: 1, // 固定为 promotionA 类
          tip: this.language.SHEIN_KEY_PWA_17533,
          discount_price: otherDiscountPrice,
          ids: this.cartsId.filter(id => {
            let cartItem = this.cartItemMap[id]
            return cartItem?.single_other_discount?.amount > 0 && cartItem?.is_checked > 0 && !this.soldoutIds.includes(id)
          })
        }
      }
      return Object.values(promotionMap)
    },
    promotionListA() {
      return this.promotionList.filter(f => f.every_body_price_type === 1)
    },
    promotionListB() {
      return this.promotionList.filter(f => f.every_body_price_type === 2)
    },
    primeDiscount() {
      let primeDiscount = this.promotion_discount_info?.find(item => item.typeId == '29')
      if(primeDiscount) {
        return {
          tip: this.language.SHEIN_KEY_PWA_21802 + ':',
          discount_price: primeDiscount.discount_price
        }
      }
      return null
    },
    primeColor() {
      return this.cartDiscountLogoA ? '#873C00' : '#C96E3F'
    },
    flashSaleOriginalPrice(){
      return this.promotionList.find(item => item.typeId == 10 && item.subTip) ? true : false
    },
    autoUsePoints() {
      const auto_use_points_info = this.cartInfo.auto_use_points_info
      return {
        usePoints: auto_use_points_info?.auto_use_points || 0,
        amountWithSymbol: auto_use_points_info?.points_discount_price?.amountWithSymbol || '',
      }
    },
    totalPriceAmountWithSymbol() {
      return amountWithSymbolTransform(this.cartInfo.totalPrice?.amountWithSymbol || '', this.locals.currencyDecPointSymbol)
    },
    discountInfoCountdownSwitch() {
      return this.cartAbtInfo.DiscountInfoSwitch.param.discount_info_countdown_switch === 'on'
    },
    promotionCountDown() {
      if(!this.discountInfoCountdownSwitch) return -1
      let timeStamp = Number.MAX_SAFE_INTEGER
      const currentTime = new Date().getTime(), oneDay = 24 * 60 * 60 * 1000
      this.promotion_discount_info.forEach(promo => {
        let end_time = this.integrityPromotionInfo[promo.id]?.end_time
        let diff_time = end_time * 1000 - currentTime
        if(diff_time > 0 && diff_time < oneDay && timeStamp > end_time) timeStamp = end_time
      })
      return timeStamp < Number.MAX_SAFE_INTEGER ? timeStamp : -1
    },
    promotionCountDownA() {
      // wiki.pageId=1360565216
      // discount_info_countdown_switch 固定在法国和波兰处理为 off
      return -1
    },
    promotionCountDownB() {
      // wiki.pageId=1360565216
      // discount_info_countdown_switch 固定在法国和波兰处理为 off
      return -1
    },
    couponCountDown() {
      if(!this.discountInfoCountdownSwitch) return -1
      let timeStamp = Number.MAX_SAFE_INTEGER
      const currentTime = new Date().getTime(), oneDay = 24 * 60 * 60 * 1000
      this.cartInfo.coupon_infos.forEach(promo => {
        let end_time = promo?.use_end_time
        let diff_time = end_time * 1000 - currentTime
        if(diff_time > 0 && diff_time < oneDay && timeStamp > end_time) timeStamp = end_time
      })
      // return 1709626665
      return timeStamp < Number.MAX_SAFE_INTEGER ? timeStamp : -1
    },
    showSubTotal() {
      // 合规站点不展示到手价时，需要展示
      if (this.isSuggested && !this.showEstimated) {
        return true
      }
      // 有积分或券时，需要展示
      // 没有积分或券也没有活动，需要展示
      return this.autoUsePoints.usePoints || this.couponInfos.length || !this.promotionList.length
    },
    priceTitleText() {
      if (this.isNewPrice) {
        return this.couponInfos?.length > 0 ? this.language.SHEIN_KEY_PWA_32256 : this.language.SHEIN_KEY_PWA_29757
      } else {
        return this.isSuggested ? this.language.SHEIN_KEY_PWA_30773 : this.language.SHEIN_KEY_PWA_29757
      }
    }
  },
  watch: {
    show: {
      handler(n, o) {
        if (n && this.flashSaleOriginalPrice) {
          daEventCenter.triggerNotice({ daId: '1-8-7-59' })
        }
        if( n ) {
          this.$emit('updateCheckedItemIds')
        }
        if(o && !n) {
          this.updateState({
            key: 'showDiscountDetails',
            value: false
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('cart', ['updateState']),
    close () {
      this.$emit('updateCheckedItemIds', [])
      this.$emit('close')
    },
    template,
    filterCheckedIdsByPromoIds(promotionIds = []) {
      return this.cartsId?.filter(id => {
        let cartItem = this.cartItemMap[id]
        let itemPromotionDiscounts = cartItem?.promotion_discount || []
        return cartItem?.is_checked > 0 && !this.soldoutIds.includes(id) && itemPromotionDiscounts.some(promo => promo?.promotion_ids?.some(promoId => promotionIds.includes(promoId)))
      }) || []
    },
    handleFold(type = '') {
      if(type == 'promotion') this.isPromotionFold = !this.isPromotionFold
      else if(type == 'coupon') this.isCouponFold = !this.isCouponFold
      daEventCenter.triggerNotice({
        daId: '1-8-7-64',
        extraData: {
          is_open: type == 'promotion' ? Number(!this.isPromotionFold) : Number(!this.isCouponFold),
          type
        }
      })
      
    },
    handleDialogShow(tip) {
      this.dialogTip = tip
      this.dialogShow = true
    },
    showHighlightDiscount(type) {
      switch (type) {
        case 'coupon':
          this.handleFold('coupon')
          triggerHighlightAnimation(this.$refs.couponsBox)
          break
        case 'gift':
          // eslint-disable-next-line no-case-declarations
          const promotionRef = this.$refs[this.isSuggested ? 'promotionBBox' : 'promotionBox']
          // eslint-disable-next-line no-case-declarations
          const dom = promotionRef.getFirstGiftItemDom()
          if (dom) {
            promotionRef.handleFold()
            triggerHighlightAnimation(dom)
          }
          break
        default:
          break
      }
    },
  }
}
</script>
<style lang="less" scoped>
.cart-checkout__discountDetails {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 70vh;

  /deep/.discount-countdown {
    color: @sui_color_promo;
    margin-left: 16/75rem;
    height: 100%;
    .count-number {
      background: #ffece9;
      border-radius: 4/75rem;
      padding: 2/75rem 4/75rem;
    }
  }
}
.cart-checkout__discountDetails-closeBtn {
  position: absolute;
  top: 21/75rem;
  right:16/75rem;
  padding: 0.1333rem;
  color: @sui_color_gray_dark2;
}
.cart-checkout__discountDetails-title {
  padding: 23/75rem 44/75rem;
  font-size: 16px;
  color: @sui_color_gray_dark1;
  text-align: center;
  font-weight: 510;
}

.cart-checkout__discountDetailsSection {
  flex: 1;
  overflow-y: auto;

  &::-webkit-scrollbar { display: none; }
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.cart-checkout__discountDetailsSection-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 19/75rem;
  line-height: 1;
}
.cart-checkout__discountDetailsSection-item {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.2133rem;
  .discountDetailsItem-sub-tip{
    font-weight: 400;
    font-size: 12px;
    color: @sui_color_gray_dark3;
  }
}
.cart-checkout__discountDetailsItem-price {
  font-size: 14px;
  color: @sui_color_discount_dark;
  white-space: nowrap;
  padding-left: 24/75rem;
  .prime-vip-svg{
    margin-right: 12/75rem;
  }
  &.prime-vip-price{
    color: #C96E3F;
  }
}
.cart-checkout__discountDetails-wrapper {
  width: 100%;
  background: #fff;
  overflow: hidden;
  border-radius: 16/75rem 16/75rem 0 0;
  .over-quota-tip{
    font-weight: 400;
    font-size: 12px;
    color: @sui_color_gray_dark3;
    margin-top: -0.2133rem;
    margin-bottom: 0.2133rem;
  }
  /deep/ .discount-li {
    display: flex;
    justify-content: space-between;
    padding: 16/75rem 24/75rem;
    font-size: 14px;
    color: #000;
    gap: 24/75rem;
    &.no-padding {
      padding: 0;
    }
    .left-label, .right-price {
      display: flex;
      align-items: center;
    }
    .left-label {
      flex-shrink: 1;
    }
    .right-price {
      flex-shrink: 0;
      max-width: 65%;
      text-align: right;
      word-break: break-all;
    }
  }
  /deep/ .promotion-price,
  .coupon-price,
  .points-price {
    .left-label {
      i {
        color: #959595;
        margin-left: 2px;
      }
    }
    .right-price { 
      color: #fa6338;
      font-weight: 700;
      /* stylelint-disable selector-max-specificity  */
      .discount-icon {
        color: @sui_color_gray_light1;
        display: flex;
        align-items: center;
      }
    }
  }
}
.detail-list {
  padding-top: 20/75rem;
  & + .detail-list{
    border-top: 0.5px solid @sui_color_gray_weak1;
  }
}
.discount-from-coupons {
  margin-top: 32/75rem;
  .sub-title {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: @sui_color_gray_dark1;
    i {
      margin-left: 10/75rem;
      color: #959595;
    }
  }
  .coupons-box {
    background-color: @sui_color_gray_weak2;
    margin-top: 12/75rem;
    margin-bottom: 16/75rem;
    padding: 16/75rem;
  }

}
.discount-from-points {
  margin-top: 16/75rem;
  font-size: 14px;
  color: @sui_color_gray_dark1;

  .sub-title {
    display: flex;
    align-items: center;
    i {
      margin-left: 10/75rem;
      color: #959595;
    }
  }
  .points-detail-box {
    margin-top: 16/75rem;
  }
  .points-detail-item {
    display: flex;
    justify-content: space-between;
    .discount-prices{ color: @sui_color_promo_dark; }
  }
}

.checked-item-imgs {
  display: flex;
  overflow-x: auto;
  gap: 12/75rem;
  padding: 16/75rem 20/75rem;
  /deep/ .item-img {
    flex-shrink: 0;
  }
  &::-webkit-scrollbar { display: none; }
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.retail-price {
  padding: 16/75rem 24/75rem;
  &__sub {
    font-size: 10px;
    font-weight: 400;
    color: @sui_color_gray_dark3;
  }
  &__line-through {
    text-decoration: line-through;
  }
}

.retail-price, .subtotal-price {
  font-size: 14px;
  font-weight: 600;
  .right-price {
    font-size: 16px;
    font-weight: 700;
  }
}
.estimated-price {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 0;
  .right-price {
    font-size: 18px;
    font-weight: 700;
  }
}

.retail-price, .subtotal-price, .points-price, .estimated-price, .prime-discount {
  .right-price { margin-right: 32/75rem; }
}
.prime-discount {
  .left-label {
    i {
      color: #959595;
      margin-left: 2px;
    }
  }
  .right-price {
    display: flex;
    align-items: center;
    gap: 8/75rem;
    span {
      font-size: 13px;
      font-weight: 700;
    }
  }
}

.estimated-tip {
  color: @sui_color_gray_dark3;
  font-size: 12px;
  padding: 8/75rem 24/75rem 16/75rem;
}
.promotion-list {
  background: #f6f6f6;
  li {
    padding: 16/75rem 24/75rem;
  }
  .promotion-item-imgs {
    display: flex;
    flex-wrap: wrap;
    gap: 14/75rem; // for place 9 imgs gap 7px, confirmed with UED
  }
  .promotion-item-content {
    margin-top: 16/75rem;
    font-size: 12px;
    color: @sui_color_gray_dark3;
  }
  .promotion-sub-tip {
    font-size: 10px;
    color: rgba(0, 0, 0, 0.30);
  }
}
.coupons-box {
  background: #f6f6f6;
  .coupon-item {
    padding: 16/75rem 24/75rem;
  }
  .coupon-item-content {
    display: flex;
    align-items: center;
    margin-top: 16/75rem;
    gap: 8/75rem;
    .coupon-discount {
      color: @sui_color_gray_dark3;
    }
  }
  .coupon-item-imgs {
    display: flex;
    flex-wrap: wrap;
    gap: 14/75rem; // for place 9 imgs gap 7px, confirmed with UED
  }
}
.BNPLPopover {
  margin-left: 12px;
  margin-right: 12px;
}

</style>
